import React from "react"

const SignatureStyle = {
  borderSpacing: "20px",
  textAlign: "center",
  marginLeft: "20px",
  fontFamily: "Arial",
  lineHeight: "1.45",
}
const ImageWrapper = {
  width: "200px",
}
const Image = {
  width: "100%",
}

const Name = {
  margin: "0",
  marginTop: "15px",
  fontWeight: "bold",
  fontSize: "22px",
}
const Job = {
  margin: "0px 0 20px 0",
  fontSize: "14px",
  lineHeight: "24px",
  textTransform: "uppercase",
}
const Link = {
  textDecoration: "none",
  color: "#153468",
  fontWeight: "bold",
}
const StandardText = {
  margin: "0",
  fontWeight: "normal",
  fontSize: "14px",
}
const Phone = {
  fontWeight: "bold",
  fontSize: "14px",
  color: "black",
  margin: "0",
}
const PhoneLink = {
  textDecoration: "none",
  color: "black",
}

const Puce = {
  fontSize: "18px",
  lineHeight: "24px",
}

const Socials = {
  marginTop: "20px",
}
const Social = {
  //
}
const SocialImg = {
  width: "25px",
  marginRight: "5px",
}

function telWithoutSpaces(number) {
  return number.replace(/\s/g, "")
}

const Signature = ({ pageContext }) => (
  <table className="signature" style={SignatureStyle}>
    <tbody>
      <tr>
        <td className="image__wrapper" style={ImageWrapper}>
          <img
            src={`http://files.myclientisrich.com/beyer-mail/${pageContext.signatureData.imagePath}`}
            alt={pageContext.signatureData.name}
            style={Image}
            width="200"
          />

          <h2 style={Object.assign({}, Name, { color: "#153468" })}>
            {pageContext.signatureData.name}
          </h2>
          <h3
            style={Object.assign({}, Job, {
              color: pageContext.signatureData.color,
            })}
          >
            {" "}
            <span
              style={Object.assign({}, Puce, {
                color: pageContext.signatureData.colorPuce,
              })}
            >
              •{" "}
            </span>
            {pageContext.signatureData.job}{" "}
            <span
              style={Object.assign({}, Puce, {
                color: pageContext.signatureData.colorPuce,
              })}
            >
              {" "}
              •
            </span>{" "}
          </h3>

          <p style={Phone}>
            <a
              href={`tel:${telWithoutSpaces(
                pageContext.signatureData.tel.fixe
              )}`}
              style={PhoneLink}
            >
              Tél. {pageContext.signatureData.tel.fixe}
            </a>
            {pageContext.signatureData.tel.fax ? (
              <>
                -{" "}
                <a
                  href={`tel:${telWithoutSpaces(
                    pageContext.signatureData.tel.port
                  )}`}
                  style={PhoneLink}
                >
                  {pageContext.signatureData.tel.port}
                </a>
                <br />
              </>
            ) : null}
          </p>

          <p style={StandardText}>{pageContext.signatureData.address}</p>

          <p style={StandardText}>
            <a href={`https://${pageContext.signatureData.url}`} style={Link}>
              {pageContext.signatureData.url}
            </a>
          </p>
          <div style={Socials}>
            {pageContext.signatureData.social.fb ? (
              <>
                <a href={pageContext.signatureData.social.fb} style={Social}>
                  <img
                    src="http://files.myclientisrich.com/beyer-mail/fb.png"
                    alt="Logo Facebook"
                    style={SocialImg}
                  />
                </a>
              </>
            ) : null}
            {pageContext.signatureData.social.linkedin ? (
              <>
                <a
                  href={pageContext.signatureData.social.linkedin}
                  style={Social}
                >
                  <img
                    src="http://files.myclientisrich.com/beyer-mail/linkedin.png"
                    alt="Logo LinkedIn"
                    style={SocialImg}
                  />
                </a>
              </>
            ) : null}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
)

export default Signature
